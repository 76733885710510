import React from "react"
import theme from "theme"
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Uvixena Agence de marketing</title>
        <meta
          name={"description"}
          content={"Créer votre empreinte numérique, un pixel à la fois "}
        />
        <meta property={"og:title"} content={"Uvixena Agence de marketing"} />
        <meta
          property={"og:description"}
          content={"Créer votre empreinte numérique, un pixel à la fois "}
        />
        <meta
          property={"og:image"}
          content={"https://uvixena.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uvixena.com/img/2464787.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://uvixena.com/img/2464787.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://uvixena.com/img/2464787.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://uvixena.com/img/2464787.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://uvixena.com/img/2464787.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://uvixena.com/img/2464787.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="112px 0"
        background="--color-primary"
        md-padding="96px 0"
        sm-padding="72px 0"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="55%"
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 40px 0px"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 24px 0px"
            color="--light"
            font="--headline1"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
          >
            Uvixena 
			<br/>
			Agence de marketing
          </Text>
          <Text
            margin="0px 0px 40px 0px"
			padding="0px 20px 0px 0px"
            color="#FFFFFF"
            font="--lead"
            lg-text-align="center"
            lg-width="80%"
          >
            Bienvenue chez Uvixena, où le voyage de votre marque commence ! En
            tant que partenaires dans le monde numérique, nous nous consacrons à
            l'élaboration de stratégies marketing sur mesure qui trouvent un
            écho auprès de votre public et produisent des résultats. Notre
            approche est à la fois simple et efficace, et se concentre sur ce
            qui compte vraiment : votre réussite.
          </Text>
          <Box
            display="flex"
            sm-flex-direction="column"
            sm-width="100%"
            sm-text-align="center"
            justify-content="flex-start"
            align-items="center"
          >
            <Link
              href="/contact"
              padding="12px 24px 12px 24px"
              color="--dark"
              background="--color-secondary"
              text-decoration-line="initial"
              font="--lead"
              border-radius="8px"
              margin="0px 16px 0px 0px"
              transition="background-color 0.2s ease-in-out 0s"
              hover-background="--color-orange"
              sm-margin="0px 0px 16px 0px"
              sm-text-align="center"
              sm-width="100%"
              hover-transition="background-color 0.2s ease-in-out 0s"
            >
              Rendez nous visite
            </Link>
          </Box>
        </Box>
        <Box
          display="flex"
          width="45%"
          justify-content="center"
          overflow-y="hidden"
          overflow-x="hidden"
          lg-width="100%"
          margin="0px 0px 40px 0px"
        >
          <Image
            src="https://uvixena.com/img/1.jpg"
            width="555px"
            max-width="100%"
            sm-width="100%"
          />
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Box
          border-radius="24px"
          margin="0px 0px 24px 0px"
        >
          <Box
            width="60%"
            display="flex"
            flex-direction="column"
            md-width="100%"
            md-align-items="center"
            md-justify-content="center"
            md-text-align="center"
            lg-width="100%"
          >
            <Text
              margin="0px 0px 16px 0px"
              font="--headline2"
              color="--dark"
              md-text-align="center"
              sm-font='normal 700 32px/1.2 "Source Sans Pro", sans-serif'
            >
              Pourquoi nous choisir?
            </Text>
          </Box>
        </Box>
        <Box
          display="grid"
          lg-flex-wrap="wrap"
          grid-template-columns="repeat(4, 1fr)"
          grid-gap="36px 4%"
          lg-grid-template-columns="repeat(2, 1fr)"
          sm-grid-template-columns="1fr"
          sm-grid-gap="12px"
        >
          <Box
            display="flex"
            flex-direction="column"
            sm-margin="0px 0px 30px 0px"
            sm-width="100%"
          >
            <Text
              padding="7px 24px 8px 24px"
              margin="0px 0px 32px 0px"
              font="--headline3"
              border-radius="50px"
              align-items="center"
              display="flex"
              justify-content="center"
              color="--light"
              width="49px"
              height="49px"
              background="--color-primary"
              sm-margin="0px 0px 20px 0px"
              lg-margin="0px 0px 18px 0px"
            >
              1
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD2"
              font="--base"
              lg-text-align="left"
            >
              Des stratégies sur mesure : Chaque campagne est conçue en fonction
              de vos objectifs uniques, ce qui garantit une attention
              personnalisée et des solutions sur mesure.
            </Text>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            sm-width="100%"
            sm-margin="0px 0px 30px 0px"
          >
            <Text
              padding="7px 24px 8px 24px"
              margin="0px 0px 32px 0px"
              font="--headline3"
              border-radius="50px"
              align-items="center"
              display="flex"
              justify-content="center"
              color="--light"
              width="49px"
              height="49px"
              background="--color-primary"
              sm-margin="0px 0px 20px 0px"
              lg-margin="0px 0px 18px 0px"
            >
              2
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD2"
              font="--base"
              lg-text-align="left"
            >
              Des décisions fondées sur des données : En nous appuyant sur les
              dernières analyses et tendances, nous prenons des décisions
              éclairées, stratégiques et efficaces.
            </Text>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            sm-margin="0px 0px 30px 0px"
            sm-width="100%"
          >
            <Text
              padding="7px 24px 8px 24px"
              margin="0px 0px 32px 0px"
              font="--headline3"
              border-radius="50px"
              align-items="center"
              display="flex"
              justify-content="center"
              color="--light"
              width="49px"
              height="49px"
              background="--color-primary"
              sm-margin="0px 0px 20px 0px"
              lg-margin="0px 0px 18px 0px"
            >
              3
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD2"
              font="--base"
              lg-text-align="left"
            >
              Excellence créative : Notre équipe créative se nourrit
              d'innovation et propose des conceptions et des contenus
              accrocheurs qui captent les cœurs et les esprits.
            </Text>
          </Box>
          <Box
            display="flex"
            flex-direction="column"
            sm-width="100%"
            sm-margin="0px 0px 0px 0px"
          >
            <Text
              padding="7px 24px 8px 24px"
              margin="0px 0px 32px 0px"
              font="--headline3"
              border-radius="50px"
              align-items="center"
              display="flex"
              justify-content="center"
              color="--light"
              width="49px"
              height="49px"
              background="--color-primary"
              sm-margin="0px 0px 20px 0px"
              lg-margin="0px 0px 18px 0px"
            >
              4
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--greyD2"
              font="--base"
              lg-text-align="left"
            >
              Orientés vers les résultats : En fin de compte, votre succès est
              notre succès. Nous nous engageons à fournir des résultats
              mesurables qui auront un impact sur vos résultats.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-7"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
        >
          <Box
            overflow-x="hidden"
            transform="translateY(0px)"
            padding="0px 0px 100% 0px"
            width="100%"
            overflow-y="hidden"
            position="relative"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            height="auto"
          >
            <Image
              src="https://uvixena.com/img/2.jpg"
              object-fit="cover"
              position="absolute"
              width="100%"
              bottom={0}
              display="block"
              top={0}
              left={0}
              right={0}
              min-height="100%"
            />
          </Box>
        </Box>
        <Box
          flex-wrap="wrap"
          flex-direction="row"
          lg-align-items="center"
          padding="4px 4px 4px 4px"
          lg-order="1"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          display="flex"
          width="50%"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
        >
          <Box width="50%" padding="12px 12px 12px 13px" display="flex">
            <Box
              width="100%"
              height="auto"
              position="relative"
              transition="transform 0.2s ease-in-out 0s"
              margin="0px 0px 0px 0px"
              overflow-x="hidden"
              overflow-y="hidden"
              transform="translateY(0px)"
              hover-transform="translateY(-10px)"
              padding="0px 0px 100% 0px"
            >
              <Image
                top="auto"
                left={0}
                right={0}
                bottom="0px"
                display="block"
                object-fit="cover"
                position="absolute"
                width="100%"
                min-height="100%"
                src="https://uvixena.com/img/3.jpg"
              />
            </Box>
          </Box>
          <Box padding="12px 12px 12px 12px" display="flex" width="50%">
            <Box
              transition="transform 0.2s ease-in-out 0s"
              hover-transform="translateY(-10px)"
              position="relative"
              transform="translateY(0px)"
              overflow-x="hidden"
              overflow-y="hidden"
              margin="0px 0px 0px 0px"
              padding="0px 0px 100% 0px"
              width="100%"
              height="auto"
            >
              <Image
                bottom="0px"
                src="https://uvixena.com/img/4.jpg"
                position="absolute"
                top="auto"
                left={0}
                min-height="100%"
                object-fit="cover"
                display="block"
                width="100%"
                right={0}
              />
            </Box>
          </Box>
          <Box width="50%" padding="12px 12px 12px 12px" display="flex">
            <Box
              margin="0px 0px 0px 0px"
              padding="0px 0px 100% 0px"
              transition="transform 0.2s ease-in-out 0s"
              height="auto"
              overflow-x="hidden"
              overflow-y="hidden"
              position="relative"
              transform="translateY(0px)"
              hover-transform="translateY(-10px)"
              width="100%"
            >
              <Image
                object-fit="cover"
                display="block"
                top="auto"
                right={0}
                bottom="0px"
                min-height="100%"
                src="https://uvixena.com/img/5.jpg"
                position="absolute"
                width="100%"
                left={0}
              />
            </Box>
          </Box>
          <Box width="50%" padding="12px 12px 12px 12px" display="flex">
            <Box
              position="relative"
              hover-transform="translateY(-10px)"
              padding="0px 0px 100% 0px"
              transform="translateY(0px)"
              transition="transform 0.2s ease-in-out 0s"
              margin="0px 0px 0px 0px"
              width="100%"
              height="auto"
              overflow-x="hidden"
              overflow-y="hidden"
            >
              <Image
                src="https://uvixena.com/img/6.jpg"
                position="absolute"
                display="block"
                top="auto"
                left={0}
                min-height="100%"
                object-fit="cover"
                width="100%"
                right={0}
                bottom="0px"
              />
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          align-items="flex-start"
          lg-align-items="center"
          sm-margin="0px 0px 20px 0px"
          display="flex"
          flex-direction="column"
          lg-width="100%"
          lg-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          lg-padding="0px 16px 16px 16px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            width="60%"
            lg-width="100%"
            md-text-align="left"
          >
            Construisons ensemble quelque chose de grand. Votre avenir commence
            maintenant.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
